import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

VuexRouterSync.sync( store, router );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

Vue.config.productionTip = false;

store.dispatch( 'entry/setAB', window._rootData.quizType );

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});

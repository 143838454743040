import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },

        {
            path: '/quiz/:id',
            component: () =>
            {
                return import( /* webpackChunkName: "quiz" */ '@/app/pages/Quiz' );
            }
        },

        {
            path: '/spin',
            name: 'Spin',
            component: () =>
            {
                return import( /* webpackChunkName: "spin" */ '@/app/pages/Spin' );
            }
        },

        {
            path: '/gewonnen',
            name: 'Prize',
            component: () =>
            {
                return import( /* webpackChunkName: "prize" */ '@/app/pages/Prize' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                if( !store.getters.prize )
                {
                    next( '/' );
                }
                else
                {
                    next();
                }
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;

import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
        },
        answers: [],
        quizType: 'a'
    },

    getters: {
        all: state => state.entry,
        quizType: state => state.quizType,
        answers: state => state.answers
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        setAB: ( state, payload ) =>
        {
            state.quizType = payload;
        },

        pushAnswer: ( state, payload ) =>
        {
            // payload { questionNum, ans }
            if( state.answers.length >= payload.questionNum )
            {
                state.answers[ payload.questionNum - 1 ] = payload.ans;
            }
            else
            {
                state.answers.push( payload.ans );
            }
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            if( payload )
            {
                context.commit( 'assign', payload );
            }

            let entry = context.getters.all;
            entry['answers'] = context.getters.answers;
            entry['quiz_type'] = context.getters.quizType;

            return new EntryProxy()
                .create( entry )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        setAB: ( context, payload ) =>
        {
            if( payload === 'a' || payload === 'b' )
            {
                context.commit( 'setAB', payload );
                return;
            }

            let version = 'a';
            if( Math.random() > 0.5 )
            {
                version = 'b';
            }

            context.commit( 'setAB', version );
        },

        giveAnswer: ( context, payload ) =>
        {
            // payload: { questionNum, ans }

            context.commit( 'pushAnswer', payload );
        }
    }
};

import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';
import quiz from './modules/quiz';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry,
        quiz
    },

    state: {
        prize: 'juice'
    },

    getters: {
        prize: state => state.prize,
        barcode: state =>
        {
            switch( state.prize )
            {
                case 'juice':
                    return '20184940';
                case 'koek':
                    return '20183226';
                case 'koffie':
                    return '20180416';
                default:
                    return null;
            }
        }
    },

    mutations: {
        savePrize: ( state, payload ) =>
        {
            state.prize = payload;
        }
    },

    actions: {
        saveResult: ( context, payload ) =>
        {
            context.commit( 'savePrize', payload );
        }
    }
});
